*{
  margin: 0;
  padding: 0;
}


.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.fade {
  opacity: 1;
  animation: fade .5s infinite;
}


@keyframes fade {
0%,100% { opacity: 0 }
50% { opacity: 1 }
}

.fadeTwo {
  opacity: .75;
  animation: fadeTwo 2s infinite;
}


@keyframes fadeTwo {
0%,100% { opacity: 0 }
50% { opacity: .75 }
}


.fly {
  position: absolute;
  animation: fly 3s linear infinite;
}

@keyframes fly {
  0% {
    left: -30%;
  }
  100% {
    left: 60%;
  }
}

.appear {
  opacity: 0;
  animation: appear 5s linear ;
}

@keyframes appear {
  0%, 50%, 100% {
    opacity: 0;
  }
  10%, 40% {
    opacity: 1;
  }
}

.gradient {
  padding: .5em 0;
  border-radius: 5px;
  background: linear-gradient(
    rgba(117, 117, 117, 0.75),
    rgba(66, 66, 66, 0.55),
    rgba(31, 31, 31, 0.5)
  );  
}

.sway {
  animation: sway 8s infinite;
  transform-origin: 50% 100% 0;
}
@keyframes sway {
  0%, 100%{ transform: rotate(-6deg);}
  50%{ transform: rotate(6deg); }
}

.joint {
  position: absolute;
  width: 100px;
  height: 100px;
  animation: moveAndRotate 3s linear forwards;
  z-index: 10;
}

@keyframes moveAndRotate {
  0% {
    right: 0;
  }
  100% {
    right: 62%
  }
}
